<script>

export default {
  name: 'Redirector',
  props: ['slug'],
  components: {
  },
  mounted: function () {
    this.$rollbar.info("Redirect: " + this.slug);
    if(this.slug.match('^/')) {
      this.$router.push(this.slug)
    } else {
      window.open(this.slug, '_blank');
      this.$router.back();
    }
  },
}
</script>

